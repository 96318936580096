export const updateLocation = (route) => {

  return (dispatch) => {
      dispatch({
      type: 'UPDATE_LOCATION',
      payload: {route: window.location.pathname},
    })
  }
  /*return ({
    type: 'UPDATE_LOCATION',
    payload: {route: route},
  })*/
}

export const loadLocation = (route) => {
  var get_path = ''
  if(route !== '' && route !== undefined) {
    localStorage.setItem('lc', route)
    get_path = route
  } else {
    get_path = localStorage.getItem('lc')
  }

  return (dispatch) => {
      dispatch({
      type: 'LOAD_LOCATION',
      payload: {route: get_path},
    })
  }
}

export const loadLocationWithMessage = (route, message) => {
  var get_path = ''
  if(route !== '' && route !== undefined) {
    localStorage.setItem('lc', route)
    get_path = route
  } else {
    get_path = localStorage.getItem('lc')
  }

  return (dispatch) => {
      dispatch({
      type: 'LOAD_LOCATION',
      payload: {route: get_path, txt_message: message},
    })
  }
}
