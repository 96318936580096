import React, { Component } from 'react';
//import axios from 'axios'
import Cookies from 'js-cookie'
import { compose } from 'redux'
//import { withRouter } from "react-router-dom"

import { connect } from 'react-redux'
import AuthService from '../services/auth/auth.service.js'
import { loadAuthUser } from '../actions/auth'
import { loadLocation } from '../actions/location'
import RenderRoutes from '../routes/renderRoutes'

import NavBar from "../components/structure/NavBar";
import NavBarAbout from "../components/structure/NavBarAbout";
import NavBarResearch from "../components/structure/NavBarResearch";
import Header from "../components/structure/header";
import Footer from "../components/structure/footer";

import ImageBG from '../images/background.png'
import { Helmet } from "react-helmet"

//import ReactGA from 'react-ga'
//const TRACKING_ID = 'G-T4X5MNWQDK' //"G-T4X5MNWQDK" // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID)

class App extends Component {
  constructor() {
    super()
    this.state = {
      loggedIn: false,
      username: null,
      role: 0,
      message: '',
      checkUser: false,
    }
  }

  componentDidMount() {
    this.onReloadPages()
    //ReactGA.pageview(window.location.pathname + window.location.search)
  }

  onReloadPages = () => {
    /*const script = document.createElement("script")
    script.src = "/gtag.js"
    script.async = true
    document.body.appendChild(script)*/
    this.props.onLoadPages()
  }

  updateUser (userObject) {
    this.setState(userObject)
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadPages()
    this.props.history.push('/login')
    document.location.href = '/login'
  }

  checkExpireSession = async () => {

    var timeout_sec = this.props.expires
    var now = new Date().getTime()
    if(timeout_sec === undefined) {
      timeout_sec = now + (30 * 60 * 1000)
    }
    setTimeout(() => {
      if(now <= timeout_sec) {
        this.handleLogout()
      }
    }, timeout_sec - now - 1000)
  }

  renderElement() {
    var get_uri = window.location.pathname
    var sep_uri = get_uri.split("/")
    get_uri = sep_uri[1]
    var get_uri2 = sep_uri[2]
    var except_pages = ["", "home", "contact-us", "research/compare"]

    if (window.location.pathname == '/research/compare' ||
        window.location.pathname == '/research/compare_new_1' ||
        window.location.pathname == '/research/compare_mgh' ||
        window.location.pathname == '/research/compare_butler' ||
        window.location.pathname == '/research/compare_hennepin' ||
        window.location.pathname == '/research/compare_atrium' ||
        window.location.pathname == '/research/compare_new_1' ||
        window.location.pathname == '/research/heated_yoga' ) {
      return (
        <RenderRoutes currRoute={this.props.route} />
      )
    } else if(this.props.uid === null || this.props.uid > 0) { // render after loading user
      if(get_uri == 'about' || get_uri == 'researches' ) {
        // Left bar menu //
        return (
          <div className="no-padding no-margin no_bg_y_repeat"
          style={{ backgroundImage: `url('${ImageBG}')` }} >
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-T4X5MNWQDK"></script>
            <script src="../gtag.js"></script>
            </Helmet>
            <Header />
              <React.Fragment>
                <RenderRoutes currRoute={this.props.route} />
              </React.Fragment>
            <Footer />
          </div>
        )
      } else {
        return (
          <div className="no-padding no-margin no_bg_y_repeat"
          style={{ backgroundImage: `url('${ImageBG}')` }} >
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-T4X5MNWQDK"></script>
            <script src="../gtag.js"></script>
            </Helmet>
            <Header />
              <React.Fragment>
                <RenderRoutes currRoute={this.props.route} />
              </React.Fragment>
            <Footer />
          </div>
        )
      }
    }
  }

  render() {
    // window.location.pathname
    return(
      <div>
        { this.renderElement() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    route: state.locationReducer.route,
    chkLoad: true,
    expires: state.authReducer.expires,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages() {
    dispatch(loadAuthUser())
    dispatch(loadLocation())
  },
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(App)
