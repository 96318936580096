export var escapeHtml = (text) => {
  return text
      .replace(/"/g, "\"")
      .replace(/'/g, "\\'");
}


export var validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var result = re.test(email)

  if (result) {
    //console.log(email + " is valid :)")
    return true
  } else {
    //console.log(email + " is not valid :(")
    return false
  }
}

export var validateAge = (text) => {
  var get_val = Number(text)
  if(get_val >= 0 && get_val <= 120) {
    return get_val.toString()
  }
}

export var validateMRN = (text) => {
  var get_val = Number(text)
  if(get_val >= 0) {
    return get_val.toString()
  }
}

export var validateNoNegativeNumber = (text) => {
  var get_val = Number(text)
  if(get_val >= 0) {
    return get_val.toString()
  }
}
