import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter} from "react-router-dom"
import { compose } from 'redux'
import axios from 'axios'
import { connectNode } from '../../utils/global'
import {Editor, EditorState, convertToRaw} from 'draft-js'
import 'draft-js/dist/Draft.css'

import PostAddIcon from '@material-ui/icons/PostAdd'
//import '../../../styles/assets/css/admin/list1.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { loadLocationWithMessage } from '../../actions/location'

class AddContent extends Component {
  constructor() {
      super();
      this.state = {
        title: '',
        summary: '',
        content: '',
        editorState: EditorState.createEmpty(),
        message: '',
        invalid: false,
      }
      this.onChange = editorState => this.setState({editorState})
  }

  componentDidMount() {
    //this.getDataList()
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.title.trim() == '' || this.state.summary.trim() == '' ||
      convertToRaw(this.state.editorState.getCurrentContent()).blocks.length == 0) {
        this.setState({
          message: 'Please fill all information.',
          invalid: true,
        })
        window.scrollTo(0, 0)
    } else {
      var uid = this.props.authReducer.uid
      var st = 0
      axios({
        method: 'post',
        url: connectNode + 'admin/content/add_new_content',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: uid,
          title: this.state.title,
          summary: this.state.summary,
          content: convertToRaw(this.state.editorState.getCurrentContent()).blocks
        }
      })
      .then((result) => {
        if(result['data'] > 0) {
          this.props.onLoadLocation('content_list', 'Created new News & Event successfully!')
          this.props.history.push('/admin/content_list')
        }
      })
    }
  }

  render() {
    return (
      <div className="row admin_page">
        <div className="small-12 columns box_border1">
        <div className="row">
          <div className="small-12 columns">
            <h2>Create News & Events</h2>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            }
          </div>
          <div className="small-12 columns float_left">
            <span className="bold">Title</span><br />
            <TextField onChange={this.handleChange} variant="outlined"
            type="text" defaultValue={this.state.title} className="textfield1 w50p"
            InputProps={{ inputProps: { name: "title" }, maxLength: 250}} />
          </div>
          <div className="small-12 columns float_left margin_top1">
            <span className="bold">Summary</span><br />
            <TextField onChange={this.handleChange} variant="outlined"
            type="text" defaultValue={this.state.summary} className="textfield1 w100p"
            InputProps={{ inputProps: { name: "summary" }}} />
          </div>
          <div className="small-12 columns float_left add_edit_content editor_height1 margin_top1">
            <span className="bold">Body</span><br />
            <Editor editorState={this.state.editorState} onChange={this.onChange} />
          </div>
          <div className="small-12 columns float_left">
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_bttn">
                Submit
            </Button>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddContent)
