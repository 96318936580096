import React from "react";
import Settings from "../components/users/settings"

const USER_ROUTES = [
  {   path: "/user/settings",
      key: "settings",
      title: "Settings",
      exact: true,
      requireAuth: true,
      component: () => <Settings />,
      showtitle: 'true',
  },
];

export default USER_ROUTES;
