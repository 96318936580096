import React from "react"
import { Link } from "react-router-dom"
import Drawer from "@material-ui/core/Drawer"
//import { Button } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import BookIcon from '@material-ui/icons/Book'
import AssessmentIcon from '@material-ui/icons/Assessment'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { CgMail } from 'react-icons/cg'

export class NavDrawer extends React.Component {
  render() {
    return (
      <Drawer
        anchor="right"
        open={this.props.drawerOpened}
        onClose={this.props.toggleDrawer(false)}
      >
        <div
          onClick={this.props.toggleDrawer(false)}
          onKeyDown={this.props.toggleDrawer(false)}
        >
          <div className="menu_list">
            <Link to="/dashboard">
              <DashboardIcon />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/dashboard">
              <BookIcon />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/dashboard">
              <AssessmentIcon />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/user/setting">
              <SettingsIcon />
            </Link>
          </div>
          <div className="menu_list">
            <Link to="/dashboard">
              <ExitToAppIcon />
            </Link>
          </div>
        </div>
      </Drawer>
    );
  }
}
