import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
//import { updateLocation } from '../../actions/location'
//import Foundation from 'foundation-sites';
import { AppBar, Toolbar } from '@material-ui/core'
import { NavDrawer } from "./NavDrawer"

class NavBarResearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpened: false
    }
  }

  toggleDrawer = booleanValue => () => {
    this.setState({
      drawerOpened: booleanValue
    })
  }

  onReloadLogoutPages = () => {
    this.props.onLoadLogoutPages()
  }

  onReloadPages = (route) => {
    this.props.onLoadPages(route)
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadLogoutPages()
  }

  handlePage(route) {
    this.onReloadPages(route)
  }

  render() {
    var get_uri = window.location.pathname
    var sep_uri = get_uri.split("/")
    var get_uri2 = sep_uri[2]

    return (
    <ul id="sideNav" className="menu vertical">
      <li className={(get_uri2=='current-projects' && "first active-trail active") || (get_uri2!='current-projects' && "first")}>
        <a href="/researches/current-projects" className="active-trail active">Current Projects</a></li>
      <li className={get_uri2=='collaborators' && "active-trail active"}><a href="/researches/collaborators">Collaborators</a></li>
      <li className={get_uri2=='fellowship-opportunities' && "active-trail active"}><a href="/researches/fellowship-opportunities">Fellowship Opportunities</a></li>
      <li className={get_uri2=='careers' && "active-trail active"}><a href="/researches/publications">Publications</a></li>
    </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    p_st: state.authReducer.p_st,
    b_step: state.authReducer.b_step,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadLogoutPages() {
    dispatch(logoutUser())
  },
  onLoadPages(route) {
    //dispatch(updateLocation(route))
    dispatch({
      type: 'UPDATE_LOCATION',
      payload: {route: route},
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBarResearch)
