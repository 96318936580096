import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
//import 'bootstrap/dist/css/bootstrap.min.css'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
import '../../styles/assets/css/compare/dauten.css'
import '../../styles/assets/css/compare/compare.css'
import { Helmet } from "react-helmet"
//import './meta.js'

class ResearchCompareButler extends Component {
  constructor() {
      super()
  }

  handleEnrollClick = async (e) => {
    window.open("https://redcap.partners.org/redcap/surveys/?s=CJM9F7FJACKYH399")

    const res = await axios.get('https://geolocation-db.com/json/')
    axios({
      method: 'post',
      url: connectNode + 'public/general/count_enroll_click',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { ip: res.data.IPv4 }
    })
    .then((result) => { })
  }

  render() {
    return (
      <div class="research COMPARE">
      <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-T4X5MNWQDK"></script>
      <script src="../gtag.js"></script>
      </Helmet>
      <Helmet>
        <script
          src="../meta.js"
        ></script>
      </Helmet>
      <noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1230911777821192&ev=PageView&noscript=1"
/></noscript>
      <div class="container-fluid sectHeader">
        <div class="row d-flex align-items-center no-margin">
          <div class="col-md-3 offset-md-1 col-sm-4 offset-sm-1 col-xs-6 offset-xs-3"><img src="/img/CompareLogo_Tag_EOMH.png" width="600" height="163" alt="COMPARE: Expanding Options for Healthcare"/> </div>
          <div class="col-md-6 offset-md-1 col-sm-5 offset-sm-1 col-xs-8 offset-xs-2 text-sm-end text-center header_hospitals">
            <p>Butler Hospital</p>
          </div>
        </div>
      </div>
      <div class="container-fluid frontIntro">
        <div class="row d-flex align-items-center g-md-5">
          <div class="col-md-3 offset-md-1 colText blocked">
            <h2>Feeling down?</h2>
            <p><em>Feeling down and can’t seem to snap out of it?</em></p>
            <p>You may be suffering from depression.</p>
            <h2>The good news:&nbsp;</h2>
            <p>Researchers are working on new treatments all the time.</p>
          </div>
          <div class="col-md-7 offset-md-0 colText">
            <h1 class="brown_txt">Join us!</h1>
            <p class="brown_txt">If you join our study, you can get treatment that may help your depression. </p>
            <p class="brown_txt">At the same time, you may be able to help other people who struggle with depression. </p>

            <div class="row d-flex">
              <div class="col-md-3 show-only-desktop">
                <img src="/img/yoga1.png" className="yoga1" />
              </div>
              <div class="col-md-6 font-size-1">
                <p class="brown_txt">When you join, you’ll receive either:</p>

                <div class="row1 show-only-mobile yoga_box1">
                  <div className="txt_w1">
                    <p><img src="/img/white1.png" class="bullet1" /><strong>12 weeks of yoga classes</strong></p>
                    <p class="brown_txt txt_w3">OR</p>
                  </div>
                  <div className="txt_w2">
                    <img src="/img/yoga1.png" className="yoga1" />
                  </div>
                  <div className="txt_w4">
                    <p><img src="/img/white1.png" class="bullet1" /><strong>12 weeks of talk therapy</strong></p>
                  </div>
                  <div className="txt_w2">
                    <img src="/img/talk1.png" className="computer1" />
                  </div>
                </div>

                <div className="show-only-desktop">
                  <p><img src="/img/white1.png" class="bullet1" /><strong>12 weeks of yoga classes</strong>.</p>
                  <p class="brown_txt">OR</p>
                  <p><img src="/img/white1.png" class="bullet1" /><strong>12 weeks of talk therapy</strong>.</p>
                </div>
              </div>
              <div class="col-md-3 show-only-desktop">
                <img src="/img/talk1.png" className="computer1" />
              </div>
              <div class="col-md-6 show-only-mobile">
                <img src="/img/yoga1.png" className="yoga1 show-only-desktop" />
                <img src="/img/talk1.png" className="computer1 show-only-desktop" />
              </div>
            </div>
            <p class="brown_txt">You can also get up to $170.00 for filling out surveys.</p>
            <div class="row d-flex">
              <div className="col-md-6 show-only-desktop">
                <p className="question_call">
                  <h1 className="brown_txt">Questions? Call us!</h1>
                  <span className="qnumber">401-479-369</span>
                </p>
              </div>
              <div className="col-md-6 enroll_button1">
                <p class="brown_txt enroll_button2"><a class="btn btn-primary" onClick={(e) => this.handleEnrollClick(e)} role="button">Click here to join</a></p>
              </div>
              <div className="col-md-6 show-only-mobile">
                <p className="question_call">
                  Questions? Call us!<br />
                  401-479-369
                </p>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div class="container-fluid sectionJoinUs">
      <div class="row d-flex">
        <div class="col-lg-5 offset-lg-1 order-lg-1 col-md-10 offset-md-1 order-md-2 order-sm-2 order-xs-2 colText">
        <h2 class="">The COMPARE study</h2>
        <p class="">Researchers and clinicians from four major health care centers in the U.S. are getting together to study new treatments right now. Our new study is called <strong>COMPARE.</strong> We want to compare two treatments for depression:</p>
        <ul class="">
          <li class="">A gentle, easy-to-do form of yoga focused on breathing, stretching, and relaxation. </li>
          <li class="">A talk therapy called<em> behavioral activation</em> that helps motivate people to get out and do things that are important to them. </li>
        </ul>
        <p class="">If we find out that both of these ways of treating depression work, we’ll have more ways to help people with depression feel better. </p>
        <p class="">But to do this, <strong class="">we need your help</strong>.</p>
        </div>
        <div class="col-lg-5 offset-lg-1 order-lg-2 col-md-12 offset-md-0 order-md-1 order-sm-1 order-xs-1 colPhoto" >
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
      </div>

      <div class="container-fluid sectionStudy">
        <div class="row align-items-center">
          <div class="col-md-6 offset-md-6 colText">
            <h2>To join the COMPARE study, you must: </h2>
            <ul>
              <li>Be 18 years of age or older </li>
              <li>Have symptoms of depression or sad mood</li>
              <li>Be able to read English  (Spanish version coming soon!)</li>
              <li>Live in Massachusetts, Minnesota, North Carolina, or Rhode Island </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container-fluid sectionCalltoAction">
        <div class="row align-items-center">
          <div class="col-lg-3 offset-lg-1 col-md-10 offset-md-1 colText blocked">
            <h2>What happens after you’re done?</h2>
            <p>After the study is over, we will look at all of the information we have to see how much the yoga classes and therapy sessions helped our participants  feel better. We will let you know what we learn. </p>
          </div>
          <div class="col-lg-6 offset-lg-1 col-md-10 offset-md-1 colText">
            <h2><img src="/img/person-computer.png"  class="img-fluid float-end" alt=""/>How can I join the study? </h2>
            <p>Just click on the link below. You’ll be asked to answer some questions to make sure that the study is a good fit for you. Then you can continue to enroll. </p>
            <p><a class="btn btn-primary" onClick={(e) => this.handleEnrollClick(e)} role="button">Click here to join</a></p>
            <p class="smaller">If you have questions about the study or how to join, you can call us at
             401-479-369. We’ll be happy to tell you more about the study. We can also help you enroll.</p>
          </div>
        </div>
      </div>
      <div class="container-fluid sectPeopleStrip">
        <div class="row">
          <div class="col"> <img src="/img/people-strip.jpg" width="2015" height="245" alt=""/> </div>
        </div>
      </div>
      <div class="container-fluid sectionFooter">
        <div class="row align-items-center">
          <div class="col-md-10 offset-md-1">
            <p>&nbsp;<br />
              Copyright © 2007-2022. The General Hospital Corporation. All Rights Reserved. | Image by <a href="https://www.freepik.com/free-photo/medium-shot-women-talking-therapy_13360967.htm" target="_blank">Freepik</a></p>
          </div>
        </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResearchCompareButler)
