import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
//import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../utils/global'

import Button from '@material-ui/core/Button'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { FaRegUserCircle } from 'react-icons/fa'
//import { loadAuthUser } from '../../actions/auth'
import { loadLocationWithMessage } from '../../actions/location'
import validator from 'validator'

class ForgotPassword extends Component {
  constructor() {
      super();
      this.state = {
        status_submit: true,
        status_complete: false,
        email: '',
        loading: false,
        message: ''
      }
      this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    // check if already logged in //
    if(this.props.authReducer.uid > 0) {
      window.location.href = "/user/settings"
    }
  }

  handleChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e) => {
      e.preventDefault()
      //const { dispatch } = this.props;
      if(this.state.email == '') {
        this.setState({
          message: 'Please provide your email',
        })
      } else {
        if(validator.isEmail(this.state.email)) {
          this.setState({
            status_submit: false,
          })
          axios({
            method: 'post',
            url: connectNode + 'user/forgot_password',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              email: this.state.email,
            }
          })
          .then((result) => {
              if(result['data'] == 0) {
                this.setState({
                  status_submit: true,
                  message: 'Sorry, this email is not existed in the system.',
                })
              } else {
                this.setState({
                  status_complete: true,
                  message: 'An email has been sent to your email address. Please follow the directions in the email to reset your password.',
                })
              }
            }
          )
        } else {
          this.setState({
            message: 'Please correct your email',
          })
        }
      }
  }

  render() {
    return (
      this.props.authReducer.uid == null &&
      <div className="row forgot_password" id="wrapper-login">
        <div className="small-12 columns">
          <div className="title">Forgot Password</div>
        </div>
        <div className="small-12 columns">
          { !this.state.status_complete && this.state.message !== '' &&
            <div className="warning_msg9">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>}
          { this.state.status_complete && this.state.message !== '' &&
          <div>
            <div className="align_center comp_icon"><CheckCircleOutlineIcon /></div>
            <div className="complete_sent_email">{ this.state.message }</div>
          </div>}
          { !this.state.status_complete &&
          <form id="forgot_password" onSubmit={this.handleSubmit}>
              <div>
                <input className="form-input"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Enter your email address"
                    value={this.state.email}
                    onChange={this.handleChange} />
              </div>
              <div className="form-group ">
                {this.state.status_submit &&
                  <Button variant="contained" color="primary" className="button"
                  onClick={this.handleSubmit} type="submit">Submit</Button>}
                {!this.state.status_submit &&
                  <Button variant="contained" disabled className="button"
                  onClick={this.handleSubmit}>Submit</Button>}
              </div>
          </form>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route) {
    dispatch(loadLocationWithMessage(route, ''))
  },
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(ForgotPassword)
//export default Login
