import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter} from "react-router-dom"
import { compose } from 'redux'
import axios from 'axios'
import { connectNode } from '../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
//import '../../../styles/assets/css/admin/list1.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../functions/pagination'
import { loadLocationWithMessage } from '../../actions/location'

class ContentList extends Component {
  constructor() {
      super();
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          show_filter_status: 2,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/content/get_content_list1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
    .then((result) => {
        if(result['data'].length > 0) {
          this.setState({
            dataItems: result['data'],
          })
        }
      }
    )
  }

  handleDelete = (e, nid) => {
    var box = window.confirm("Are you sure you want to delete this item?")
    if (box == true) {
      axios({
        method: 'post',
        url: connectNode + 'admin/content/delete_content',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          nid: nid,
        }
      })
      .then((result) => {
        this.props.onLoadLocation('content_list', 'Delete successfully!')
        this.getDataList()
      })
    } else {
      e.target.checked = false
    }
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  render() {
    return (
      <div className="row admin_page">
        <div className="small-12 columns box_border1">
        <div className="row">
          <div className="small-12 medium-6 columns">
            <h2>Content</h2>
          </div>
          <div className="small-12 medium-4 columns align_right">
            <NavLink to='/admin/add_content' className="new_bttn margin_top1">
              New News & Events
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Author</th>
                    <th>Updated</th>
                    <th>Operations</th>
                  </tr>
                </thead>
                <tbody>
              {this.state && this.state.dataItems && this.state.pageOfItems.map((item, index) =>
                <tr key={index}>
                  <td>{item.title}</td>
                  <td className="align-left">{item.type_name}</td>
                  <td>{item.name}</td>
                  <td>
                    {item.date_edited}
                  </td>
                  <td>
                    <a href="/admin/edit_content/{$item.nid}">Edit</a> &nbsp;
                    {item.type == 'news_events' && <a onClick={(e) => this.handleDelete(e, item.nid)}>Delete</a>}
                  </td>
                </tr>
              )}

              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan={5} >
                    <Pagination items={this.state.dataItems} pageSize={30} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ContentList)
