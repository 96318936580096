import React from "react";
//import { RenderRoutes } from "./global"
//import Test1 from "../components/users/Test1";
import Login from "../components/auth/login"
import ForgotPassword from "../components/auth/forgot_password"
import ResetPassword from "../components/auth/reset_password"

const AUTH_ROUTES = [
  {   path: "/login",
      key: "login",
      title: "Login",
      exact: true,
      requireAuth: false,
      component: () => <Login />,
      showtitle: 'true',
  },
  {   path: "/forgot_password",
      key: "forgot_password",
      title: "Forgot Password",
      exact: true,
      requireAuth: false,
      component: () => <ForgotPassword />,
      showtitle: 'true',
  },
  {   path: "/reset_password/:token",
      key: "reset_password",
      title: "Reset Password",
      exact: true,
      requireAuth: false,
      component: () => <ResetPassword />,
      showtitle: 'true',
  },
];

export default AUTH_ROUTES;
