import React from 'react';
//import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client'
import { BrowserRouter } from "react-router-dom"
//import { Router  } from "react-router-dom"
import { Provider } from 'react-redux'
import App from './components/App.jsx';
//import * as serviceWorker from './utils/serviceWorker'
import configureStore from './stores/configureStore'

import './styles/assets/css/foundation-float.css'
import './styles/assets/css/foundation-icons/foundation-icons.css'
import './styles/assets/css/foundation.css'
import './styles/assets/css/breviloquent-dauten.css'
import './styles/assets/css/template_nicha.css'
import './styles/assets/css/template.css'
import './styles/assets/css/dauten2023.css'
//import './styles/assets/css/compare/resources.css'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

if (typeof window !== 'undefined') {
  root.render(
    <Provider store={configureStore()} key="provider">
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>,
  );
}
