import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
import NavBarAbout from "../../components/structure/NavBarAbout"
import Background from '../../images/title-about.jpg'
import Announcement from "../structure/announcement"
import OurTeam from "./ourteam"

class AboutStaff extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          content: '',
          name: '',
          date_time: '',
          title: '',
          body: null,
          image: '',
      }
  }

  componentDidMount() {
    this.getContent()
  }

  getContent = () => {
    var sid = this.props.match.params.staff
    var name = ''
    var title = ''
    var body = ''

    axios({
      method: 'post',
      url: connectNode + 'admin/content/get_staff_1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        sid: sid,
      }
    })
    .then((result) => {
        this.setState({
          content: result['data'].content,
          name: result['data'].name,
          title: result['data'].title,
          image: result['data'].image,
        })
      }
    )
  }

  render() {
    var sectionStyle = {
      backgroundColor: 'rgba(0,52,86,1.00)',
      padding: '3% 0% 3% 0%',
      color: 'rgba(255,255,255,1.0)',
      backgroundImage: `url(${Background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    };
    return (
    <div>
      <div class="container-fluid sectionTitle" style={ sectionStyle }>
        <div class="row">
          <div class="col-md-10 offset-md-1 col-sm-12 offset-sm-0">
            <h1>Our Team</h1>
          </div>
        </div>
      </div>
      <div class="container-fluid sectionBody">
        <div class="row">
          <div class="col-lg-3 offset-lg-1 col-md-10 offset-md-1 order-lg-1 order-2 sideBar">
            <NavBarAbout loggedIn={this.state.loggedIn} />
            <Announcement />
          </div>
          <div class="col-lg-7 offset-lg-0 col-md-10 offset-md-1 col-sm-12 offset-sm-0 order-lg-2 order-1 px-3 pageBody"> <img src={"../img/staff/"+this.state.image} class="img-fluid float-start bioPhoto" alt={this.state.name} />
            <h2 class="bioName">{this.state.name}</h2>
            <p class="bioTitle">{this.state.title}</p>
            <p>{ReactHtmlParser(this.state.content)}</p>
          </div>
        </div>
      </div>

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AboutStaff)

/**/
