import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'

class News extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          news_list: [],
          title: '',
      }
  }

  componentDidMount() {
    this.getContent()
  }

  getContent = () => {
    var title = 'News & Events'
    var category_name = 'news_events'

    axios({
      method: 'post',
      url: connectNode + 'admin/content/get_news_list1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        category_name: category_name,
      }
    })
    .then((result) => {
      this.setState({
        news_list: result['data'],
        title: title,
      })
    })
  }

  render() {
    return (
    <div>
      <div className="row">
        <div className="small-12 columns title2">
          <h1>
          {this.state.title}
          </h1>
        </div>
      </div>
      <div className="row">
        {this.state.news_list.map(each =>
        <div className="small-12 columns each_news_list">
          <h2><a href="/node/{$each.nid}">{each.title}</a></h2>
          <div className="submitted_by">Submitted by {each.name} on {each.date_posted}</div>
          <div className="news_intro">{ReactHtmlParser(each.body_summary)}</div>
          {each.nid != 21 &&
          <div><a href="/node/{$each.nid}">Read more</a></div>}
        </div>
        )}
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(News)
