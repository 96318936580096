import { combineReducers } from 'redux'
import authReducer from './authReducer'
import locationReducer from './locationReducer'
//import { loadingBarReducer } from 'react-redux-loading'

export default combineReducers({
  authReducer,
  locationReducer,
  //loadingBar: loadingBarReducer,
})
