import React from "react";
import ContentList from "../components/admin/content_list"
import AddContent from "../components/admin/add_content"
import EditContent from "../components/admin/edit_content"

const ADMIN_ROUTES = [
  {   path: "/admin/content_list",
      key: "content_list",
      title: "Content list",
      exact: true,
      requireAuth: true,
      component: () => <ContentList />,
      showtitle: 'true',
  },
  {   path: "/admin/add_content",
      key: "add_content",
      title: "Add Content",
      exact: true,
      requireAuth: true,
      component: () => <AddContent />,
      showtitle: 'true',
  },
  {   path: "/admin/edit_content/:nid",
      key: "edit_content",
      title: "Edit Content",
      exact: true,
      requireAuth: true,
      component: () => <EditContent />,
      showtitle: 'true',
  },
  {   path: "/admin/delete_content/:nid",
      key: "delete_content",
      title: "Delete Content",
      exact: true,
      requireAuth: true,
      component: () => <ContentList />,
      showtitle: 'true',
  },
  /*{   path: "/admin/add_participant",
      key: "add_participant",
      title: "New Participant",
      exact: true,
      requireAuth: true,
      component: () => <NewParticipant />,
      showtitle: 'true',
  },*/
];

export default ADMIN_ROUTES;
