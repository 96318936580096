import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
import ReactHtmlParser from 'react-html-parser'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'

class Announcement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      body: "",
    }
  }

  componentDidMount() {
    var getPath = window.location.pathname.split("/")
    var title = ''
    var body = ''
    if(getPath[1] == 'about' && getPath[2] == 'dauten-family') {
      title = 'Five Years of the Dauten Family Center'
      body = '“Since we established the Dauten Center in 2017, we leveraged our resources to get highly competitive grants, launch innovative pilot studies and an innovative clinical model, as well as grow our team. Most importantly, we had the gift of time to think, plan, meet, write, and collaborate with people at other institutions. We could only do this because of the support of our generous donors to whom I will be eternally grateful.” --Andrew Nierenberg, MD, Director'
    } else if(getPath[1] == 'bipolar' && getPath[2] == 'resources') {
      title = 'Massachusetts-Based Resources'
      body = 'You can view and download a complete list of Massachusetts-based resources <a href="../files/Resource_List.pdf" Download>here</a>.'
    } else if((getPath[1] == 'contact' && getPath[2] == 'contact') || (getPath[1] == 'fitt-bd' && getPath[2] == 'fitt-bd')) {
      if(getPath[1] == 'contact' && getPath[2] == 'contact') {
        title = 'Stay in touch with DCBI'
      }
      body = 'Follow us on social media.<br />'
    }
    this.setState({
      title: title,
      body: body,
    })
  }

  render() {
    const getPath = window.location.pathname.split("/")
    return (
      <div class="announcement">
        <h3>{this.state.title}</h3>
        <p>{ReactHtmlParser(this.state.body)}
        {((getPath[1] == 'contact' && getPath[2] == 'contact') ||
          (getPath[1] == 'fitt-bd' && getPath[2] == 'fitt-bd')) &&
          <>
          <a href="https://www.facebook.com/MGHDautenCenter" className="contact_social"><FacebookIcon /></a>
          <a href="https://www.instagram.com/mghdautencenter" className="contact_social"><InstagramIcon /></a>
          <a href="https://twitter.com/mghdautencenter" className="contact_social"><TwitterIcon /></a>
          </>
        }</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    p_st: state.authReducer.p_st,
    b_step: state.authReducer.b_step,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadLogoutPages() {
    dispatch(logoutUser())
  },
  onLoadPages(route) {
    //dispatch(updateLocation(route))
    dispatch({
      type: 'UPDATE_LOCATION',
      payload: {route: route},
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Announcement)
