import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
import NavBarAbout from "../../components/structure/NavBarAbout"
import Background from '../../images/title-about.jpg'
import Announcement from "../structure/announcement"
import OurTeam from "./ourteam"

class About extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          content: '',
          name: '',
          date_time: '',
          title: '',
          banner_title: '',
          body: '',
      }
  }

  componentDidMount() {
    this.getContent()
  }

  getContent = () => {
    var nid = 0
    var title = ''
    var banner_title = ''
    var get_uri2 = this.props.match.params.page
    var body = null

    if(get_uri2 == 'our-team') {
      nid = 2
      title = 'Who We Are'
      banner_title = 'Our Team'
      body = <OurTeam />
    } else if(get_uri2 == 'dauten-family') {
      nid = 9
      title = 'Dauten Family'
      banner_title = title
    } else if(get_uri2 == 'scientific-advisory-board') {
      nid = 8
      title = 'Scientific Advisory Board'
      banner_title = title
    }

    if(get_uri2 != 'our-team') {
      axios({
        method: 'post',
        url: connectNode + 'admin/content/get_content_1',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { nid: nid }
      })
      .then((result) => {
        this.setState({
          title: title,
          banner_title: banner_title,
          body: ReactHtmlParser(result['data'].body_value),
        })
      })
    } else {
      this.setState({
        title: title,
        body: body,
        banner_title: banner_title,
      })
    }
  }

  render() {
    var sectionStyle = {
      backgroundColor: 'rgba(0,52,86,1.00)',
      padding: '3% 0% 3% 0%',
      color: 'rgba(255,255,255,1.0)',
      backgroundImage: `url(${Background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    };
    return (
    <div>
      <div className="container-fluid sectionTitle" style={ sectionStyle }>
        <div className="row">
          <div className="col-md-10 offset-md-1 col-sm-12 offset-sm-0">
            <h1>{this.state.banner_title}</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid sectionBody">
        <div className="row">
          <div className="col-lg-3 offset-lg-1 col-md-10 offset-md-1 order-lg-1 order-2 sideBar">
            <NavBarAbout loggedIn={this.state.loggedIn} />
            <Announcement />
          </div>
          <div className="col-lg-7 offset-lg-0 col-md-10 offset-md-1 col-sm-12 offset-sm-0 order-lg-2 order-1 px-3 pageBody">
          { this.props.match.params.page != 'dauten-family' &&
            <div>
              <h2>{this.state.title}</h2>
              {this.state.body}
            </div>
          }
          { this.props.match.params.page == 'dauten-family' &&
            <div>
            <h2><img src="../img/Dautens.jpg" class="img-fluid" alt=""/> </h2>
            {this.state.body}
            </div>
          }
          </div>
        </div>
      </div>

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(About)

/**/
