import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
import '../../styles/assets/css/compare/resources.css'
import { Helmet } from "react-helmet"
import ImageLogo from '../../images/CompareLogo_Tag_EOMH.png'

class ResearchCompareResourceList extends Component {
  constructor() {
      super()
  }

  render() {
    return (
    <div className="resource_list main">
    <div class="resources_page">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="header">
      	<img src={ImageLogo} />
      	<h1>National Well-Being Resources</h1>
      </div>
      <div className="section 988">
      	<h2>988 Suicide and Crisis Lifeline</h2>
      	<p>Available 24/7 to talk about your concerns with trained professionals who are ready to help.</p>
      	<ul>
      		<li>CALL or TEXT: <a href="tel:988">988</a></li>
      		<li>Chat: <a href="https://988lifeline.org/chat/">https://988lifeline.org/chat/</a></li>
      		<li>Website: <a href="https://988lifeline.org/">https://988lifeline.org/</a></li>
      	</ul>
      </div>
      <div className="section mhapps">
      	<h2>Mental Health Apps</h2>
      	<p>These completely free, interactive mobile applications provide information, tools, assessments, and support resources for people and families managing mental health.</p>
      	<ul>
      		<li>Visit <a href="https://www.mentalhealthapps.org/">https://www.mentalhealthapps.org/</a></li>
      	</ul>
      </div>
      <div className="section calmapp">
      	<h2>Calm App</h2>
      	<p>An app for sleep, meditation, and relaxation. Free resources include daily mindfulness challenges, gratitude journal, calm intention guidebooks, managing stress workbooks, and more!</p>
      	<ul>
      		<li>Visit <a href="https://www.calm.com/blog/mindfulness-resources">https://www.calm.com/blog/mindfulness-resources</a></li>
      	</ul>
      </div>
      <div className="section headspaceapp">
      	<h2>Headspace App</h2>
      	<p>Science-backed meditation and mindfulness tools to support mental health and happiness.</p>
      	<ul>
      		<li>Visit <a href="https://www.headspace.com/">https://www.headspace.com/</a></li>
      	</ul>
      </div>
      <div className="section dbsa">
      	<h2>DBSA Depression Support Groups</h2>
      	<p>Locate an in-person or online group at the Depression and Bipolar Support Alliance.</p>
      	<ul>
      		<li>Visit <a href="https://www.dbsalliance.org/">https://www.dbsalliance.org/</a></li>
      	</ul>
      </div>
      <div className="section psychtoday">
      	<h2>Psychology Today</h2>
      	<p>Find detailed listings for mental health professionals in the US.</p>
      	<ul>
      		<li>Visit <a href="https://www.psychologytoday.com/us/therapists">https://www.psychologytoday.com/us/therapists</a></li>
      	</ul>
      </div>
    </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResearchCompareResourceList)

