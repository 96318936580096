import { createStore, applyMiddleware } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import thunk from 'redux-thunk'
import rootReducer from '../reducers/index'

export default () => {
  const middlewares = [thunk, apiMiddleware]

  if (process.env.NODE_ENV !== 'production') {
    //
  }

  const store = createStore(
    rootReducer,
    applyMiddleware(...middlewares)
  )

  if (module.hot) {
      module.hot.accept('../reducers', () => {
      import('../reducers').then((nextRootReducer) =>
        store.replaceReducer(nextRootReducer.default)
      )
    })
  }

  return store
}
