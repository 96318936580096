import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
import NavBarResearch from "../../components/structure/NavBarResearch"

class Research extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          content: '',
          name: '',
          date_time: '',
          title: '',
      }
  }

  componentDidMount() {
    this.getContent()
  }

  getContent = () => {
    var nid = 0
    var title = ''
    var get_uri2 = this.props.match.params.page

    if(get_uri2 == 'current-projects') {
      nid = 3
      title = 'Current Projects'
    } else if(get_uri2 == 'collaborators') {
      nid = 4
      title = 'Collaborators'
    } else if(get_uri2 == 'fellowship-opportunities') {
      nid = 7
      title = 'Fellowship Opportunities'
    } else if(get_uri2 == 'careers') {
      nid = 24
      title = 'Careers'
    } else if(get_uri2 == 'publications') {
      nid = 11
      title = 'Publications'
    }

    axios({
      method: 'post',
      url: connectNode + 'admin/content/get_content_1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        nid: nid,
      }
    })
    .then((result) => {
        this.setState({
          content: result['data'].body_value,
          name: result['data'].name,
          date_time: result['data'].date_posted,
          title: title,
        })
      }
    )
  }

  render() {
    return (
    <div>
      <div className="row">
        <div className="small-12 columns title2">
          <h1>
          {this.state.title}
          </h1>
        </div>
      </div>
      <div className="row bg_url1" data-equalizer="user" data-equalize-on="medium"
        id="main_eq" >
        <div className="small-12 medium-3 columns left_menu show-for-medium" data-equalizer-watch="user">
          <NavBarResearch loggedIn={this.state.loggedIn} />
        </div>
        <div className="small-12 medium-9 columns" data-equalizer-watch="user">
          <div className="about" >
            <div className="wrapper row">
              <div className="small-12 columns">
                <div className="content">
                  {ReactHtmlParser(this.state.content)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Small size only */}
        <div className="small-12 medium-3 columns left_menu show-for-small-only" data-equalizer-watch="user">
          <NavBarResearch loggedIn={this.state.loggedIn} />
        </div>
      </div>
    </div>



    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Research)
