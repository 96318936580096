import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'

class OurTeam extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          body: [],
      }
  }

  componentDidMount() {
    this.getContent()
  }

  getContent = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/content/get_staff_list1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { }
    })
    .then((result) => {
      this.setState({
        body: result['data'],
      })
    })
  }

  render() {
    return (
    <div>
        <div class="row staffPeople">
        {this.state.body.map(each =>
          <>
          {each.order == 1 &&
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6 d-none d-sm-block">&nbsp;</div>}
          {each.order <= 2 &&
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6 staffPhoto">
            <p><a href={`/about_team/${each.uid}`}><img src={`../img/staff/${each.image}`} width="300" height="300" class="img-fluid" alt={each.name} /></a></p>
            <h3><a href={`/about_team/${each.uid}`}>{each.name}</a></h3>
            <h4>{each.title}</h4>
          </div>}
          {each.order == 2 &&
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6 d-none d-sm-block">&nbsp;</div>}
          {each.order > 2 &&
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6 staffPhoto">
            <p><a href={`/about_team/${each.uid}`}><img src={`../img/staff/${each.image}`} width="300" height="300" class="img-fluid" alt={each.name} /></a></p>
            <h3><a href={`/about_team/${each.uid}`}>{each.name}</a></h3>
            <h4>{each.title}</h4>
          </div>}
          </>
        )}
        </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OurTeam)

/**/
