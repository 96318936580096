import { SAVE_USER_AUTH, LOAD_USER_AUTH, LOGOUT_USER_AUTH } from '../const/authConst'

export default function authReducer (state = [], action) {
  switch(action.type) {
    case SAVE_USER_AUTH:
      return action.token
    case LOAD_USER_AUTH :
      //console.log('authReducer: LOAD_USER_AUTH')
      return action.payload
    case LOGOUT_USER_AUTH :
      //console.log('authReducer: LOGOUT_USER_AUTH')
      return action.payload
    /*case RECEIVE_REVIEW :
      return {
        ...state,
        ...action.route
    }*/
    default :
      return state
  }
}
