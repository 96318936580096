import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
//import { updateLocation } from '../../actions/location'
//import Foundation from 'foundation-sites';
import { AppBar, Toolbar } from '@material-ui/core'
import ListAltIcon from '@material-ui/icons/ListAlt';
import DashboardIcon from '@material-ui/icons/Dashboard'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import MenuBook from '@material-ui/icons/MenuBook'
import DescriptionIcon from '@material-ui/icons/Description'
import VideoLibrary from '@material-ui/icons/VideoLibrary'
import Help from '@material-ui/icons/Help'
import { CgMail } from 'react-icons/cg'
//import BookIcon from '@material-ui/icons/Book'
import AssessmentIcon from '@material-ui/icons/Assessment'
import SettingsIcon from '@material-ui/icons/Settings'
import { NavDrawer } from "./NavDrawer"

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpened: false
    }
  }

  toggleDrawer = booleanValue => () => {
    this.setState({
      drawerOpened: booleanValue
    })
  }

  onReloadLogoutPages = () => {
    this.props.onLoadLogoutPages()
  }

  onReloadPages = (route) => {
    this.props.onLoadPages(route)
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadLogoutPages()
  }

  handlePage(route) {
    this.onReloadPages(route)
  }

  render() {
    var url_origin = window.location.origin
    return (
      <div className="main_menu">
      <AppBar position="static">
        <Toolbar>
          <div className="show-for-medium">
            { this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/participant_list' activeClassName="menu_list_active">
                <ListAltIcon />
                <div className="menu_list_name">PARTICIPANTS</div>
              </NavLink>
            </div> }
            { this.props.role == 5 &&
            <div className="menu_list">
              <NavLink to='/member/dashboard' activeClassName="menu_list_active" onClick={this.handlePage('dashboard')}>
                <DashboardIcon />
                <div className="menu_list_name">DASHBOARD</div>
              </NavLink>
            </div>}
            { this.props.role == 5 &&
            <div className="menu_list">
              <NavLink to='/member/assessment_evaluation' activeClassName="menu_list_active">
                <AssessmentIcon />
                <div className="menu_list_name">ASSESSMENTS & EVALUATION</div>
              </NavLink>
            </div>}
            { this.props.role == 5 &&
            <div className="menu_list">
              <NavLink to='/member/exercise_videos' activeClassName="menu_list_active">
                <VideoLibrary />
                <div className="menu_list_name">EXERCISE VIDEOS</div>
              </NavLink>
            </div>}
            { this.props.role == 5 &&
            <div className="menu_list">
              <NavLink to='/member/participant_manual' activeClassName="menu_list_active">
                <MenuBook />
                <div className="menu_list_name">PATIENT'S MANUAL</div>
              </NavLink>
            </div>}
            { this.props.role == 5 &&
            <div className="menu_list">
              <NavLink to="/member/participant_contract" activeClassName="menu_list_active">
                <DescriptionIcon />
                <div className="menu_list_name">PATIENT'S CONTRACTS</div>
              </NavLink>
            </div>}
            { this.props.role == 5 &&
            <div className="menu_list">
              <NavLink to="/member/help" activeClassName="menu_list_active">
                <Help />
                <div className="menu_list_name">HELP</div>
              </NavLink>
            </div>}
            <div className="menu_list margin-bttom-1">
              <NavLink to="/user/settings" activeClassName="menu_list_active">
                <SettingsIcon />
                <div className="menu_list_name">SETTINGS</div>
              </NavLink>
            </div>
          </div>
        </Toolbar>
      </AppBar>

        <NavDrawer
          drawerOpened={this.state.drawerOpened}
          toggleDrawer={this.toggleDrawer}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    p_st: state.authReducer.p_st,
    b_step: state.authReducer.b_step,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadLogoutPages() {
    dispatch(logoutUser())
  },
  onLoadPages(route) {
    //dispatch(updateLocation(route))
    dispatch({
      type: 'UPDATE_LOCATION',
      payload: {route: route},
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)

//export default NavBar;

/*<div className="menu_list">Dashboard</div>*/
