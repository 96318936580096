import { LOAD_LOCATION } from '../const/locationConst' // UPDATE_LOCATION

export default function locationReducer (state = [], action) {
  switch(action.type) {
    /*case UPDATE_LOCATION:
      return action.payload*/
    case LOAD_LOCATION:
      return action.payload
    default :
      return state
  }
}
